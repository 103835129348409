<template>
	<div style="">
		<!-- <div class="my-banner" style="height:400px;">
			<img crossorigin :src="bannerImage" alt="" class="banner-img" style="width:100%;height:25vw" />
		</div> -->
		<div style="text-align:center;background-color:#4b80c3;padding:10px;">
			<h1 style="color:#f1f1f1;letter-spacing: 4px;">{{ this.$route.query.nav === "新闻资讯" ? "新闻资讯" : "通知公告" }}</h1>
		</div>
		<el-row style="max-width:1200px;margin: auto;">
			<el-col :span="6">
				<div class="block" style="padding-top:10px;width:300px;">
					<a style="color: #000000;font-weight: bold;font-size: large;">
						{{ $route.query.nav }}
					</a>
					<el-carousel height="174px" :interval="play_interval - 1000" indicator-position="outside" style="margin-top:10px">
						<el-carousel-item v-for="item in focusArticleList" :key="item.id">
							<a :href="'/view_article?nav=' + $route.query.nav + '&id=' + item.id">
								<img crossorigin :src="base.apiServer + item.focus_image_url" style="width:100%;height:150px" />
							</a>
							<span>{{ item.title }}</span>
						</el-carousel-item>
					</el-carousel>
				</div>
			</el-col>
			<el-col :span="18" style="padding-bottom:20px;padding-left:10px;">
				<div class="grid-content bg-purple" style="margin-top:4px;margin-bottom:10px;">
					<table class="news_table">
						<tr>
							<td>
								<a>{{ $route.query.nav }}</a>
							</td>
							<td>
								<a></a>
							</td>
						</tr>
						<tr v-for="item in allArticleList" :key="item.title">
							<td>
								<a :href="'/view_article?nav=' + $route.query.nav + '&id=' + item.id">{{ item.title }}</a>
							</td>
							<td>
								<a :href="'/view_article?nav=' + $route.query.nav + '&id=' + item.id">{{ item.release_time.substring(0, 10) }}</a>
							</td>
						</tr>
					</table>
				</div>
				<div style="text-align: center;">
					<el-pagination
						background
						layout="total,prev, pager, next"
						:page-size="pagination.size"
						:total="pagination.count"
						style="margin:0 auto"
						@current-change="changePage"
					></el-pagination>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import base from "@/base.js";
import { apiListArticle } from "@/api/pub/article.js";
import newsBannerImage from "@/assets/images/banner/news.jpg";
import noticeBannerImage from "@/assets/images/banner/notice.jpg";
export default {
	data() {
		return {
			base: base,
			bannerImage: this.$route.query.nav === "新闻资讯" ? newsBannerImage : noticeBannerImage,
			play_interval: 5000,
			focusArticleList: [],
			allArticleList: [],
			pagination: {
				start: 0,
				size: 20,
				count: 0
			}
		};
	},
	methods: {
		// 获取文章列表
		async listArticle() {
			try {
				let formData = new FormData();
				formData.append("start", 0);
				formData.append("limit", 5);
				formData.append("title", "");
				formData.append("text", "");
				formData.append("status", 1);
				formData.append("is_focus", true);
				formData.append("start_time", new Date(0, 1));
				formData.append("end_time", new Date(9999, 1));
				formData.append("category_label", this.$route.query.nav);
				/* console.log("===========formData===========");
				for (var [a, b] of formData.entries()) {
					console.log(a + ":", b || '""');
				} */
				//获取焦点文章
				const response = await apiListArticle(formData);
				//console.log(response);
				if (response.data.code == 200) {
					this.focusArticleList = response.data.data.articleList;
					//console.log("focusArticleList>>", this.focusArticleList);
				} else {
					console.error("异常>>", response.data);
					this.$message.error(response.data.message);
				}

				formData.delete("is_focus");
				//获取对应type的全部文章
				formData.set("start", this.pagination.start);
				formData.set("limit", this.pagination.size);
				const articleResponse = await apiListArticle(formData);
				//console.log(articleResponse);
				if (articleResponse.data.code == 200) {
					this.allArticleList = articleResponse.data.data.articleList;
					this.pagination.count = articleResponse.data.data.count;
					//console.log("allArticleList>>", this.allArticleList);
				} else {
					this.$message.error(newsResponse.data.message);
					console.error("异常>>", newsResponse.data);
				}
			} catch (error) {
				console.error("异常>>", error);
			}
		},
		updateActive() {
			//console.log(this.$parent.$parent);
			this.$parent.$parent.$parent.updateActiveIndex();
		},
		changePage(page) {
			this.pagination.start = (page - 1) * this.pagination.size;
			this.listArticle();
		}
	},
	mounted() {
		//页面渲染完成调用方法获取数据
		this.listArticle();
		this.updateActive();
	},
	watch: {
		$route() {
			//参数改变则重新获取数据
			this.listArticle();
			this.updateActive();
		}
	}
};
</script>

<style lang="scss" scoped>
.my-banner {
	width: 100%;
	height: 400px;
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.news_table {
	background-color: #ffffff;
	width: 100%;
	padding-left: 10px;
}

.news_table a {
	color: #3b3b3b;
}
.news_table tr:first-child a {
	color: #000000;
	font-weight: bold;
	font-size: large;
}
.news_table td {
	padding: 5px;
}
.news_table td:last-child {
	padding-right: 50px;
	text-align: right;
}
a {
	text-decoration: none;
}
</style>
